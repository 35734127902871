<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="">

        <div class="">
          <label class="p-col-fixed txt-right">Công ty <span style="color: red">*</span></label>
          <div class="p-col">
            <MultiSelect class="multiselect input_text border_color " v-model="selectedCompany" :options="company" optionLabel="code" optionValue="id" placeholder="Chọn" />
            <span slot="no-options" @click="$refs.select.open = false">
            </span>
            <input class="input_tmp_validator" id="company">
          </div>
          <!-- </div> -->
        </div>
        <div class="">
          <label class="p-col-fixed txt-right">Mã HSCT <span v-if="mode != 'edit'" style="color: red">*</span></label>
          <div class="p-col">
            <inputText :disabled="mode == 'edit'" 
              v-model="code"
              required
              class="p-col-width input_text"
              maxLength="50"
              placeholder="Nhập"
            />
            <input class="input_tmp_validator" id="code">
          </div>
          <!-- </div> -->
        </div>
        <div class="">
          <label class="p-col-fixed txt-right">Tên hồ sơ chứng từ <span style="color: red">*</span></label>
          <div class="p-col">
            <inputText
              required
              class="p-col-width input_text"
              v-model="name"
              placeholder="Nhập"
              maxLength="255"
            />
            <input class="input_tmp_validator" id="name">
          </div>
          <!-- </div> -->
        </div>
        <div class="">
          <label class="p-col-fixed txt-right">Loại hồ sơ <span style="color: red">*</span></label>
          <div class="p-col">
            <div class="mb-3">
              <Checkbox id="type1" v-model="type" value="DELIVERY" />
              <label class="mb-0" for="type1">Giao hàng</label>
            </div>
            <div>
              <Checkbox id="type2" v-model="type" value="RETURN"/>
              <label class="mb-0" for="type2">Trả hàng</label>
            </div>
            <input class="input_tmp_validator" id="type">
          </div>
          <!-- </div> -->
        </div>
        <div class="p-col-fixed flex justify-content-center mt-3 align-items-center">
          <Checkbox id="active" v-model="active" :binary="true" />
          <label class="mb-0" for="active">Hiển thị</label>
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning return-style-button"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  data() {
    return {
      company: [],
      selectedCompany: null,
      name: null,
      code: null,
      type: [],
      active: true
    };
  },
  async mounted() {
    await this.getListCompany();
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.name = this.model.name;
      this.code = this.model.code;
      this.type = this.model?.type ? this.model.type.split(";") : [];
      var document_company = this.model?.document_company ?? [];
      var companyArr = [];
      document_company.map(m => {
        companyArr.push(m?.company?.id);
      })
      this.selectedCompany = companyArr;
      this.active = this.model.active;
    }
  },
  methods: {
    async getListCompany() {
      var gqLQueryList = DataServices.getList('company', {"fields" :'id name code'});
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: {created_at: 'desc'}
        }
      });
      this.company = resData.data['company'];
    },
    backToList() {
      this.$emit("back");
    },
    async checkCodeExist() {
      var gqLQueryList = DataServices.getList('document');
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: {
            code: {_eq: this.code.trim()},
            deleted: {_eq: false}
          },
          orderBy: {created_at: 'desc'}
        }
      });
      const document = resData.data['document'];
      return (document.length > 0);
    },
    async validateData() {
      this.validationErrors = {};

      let company_add = document.getElementById("company");
      if (
        this.company == undefined ||
        this.company == null ||
        this.company == "" ||
        this.company.length < 1
      ) {
        company_add.setCustomValidity("Công ty không được để trống!");
      } else {
        company_add.setCustomValidity("");
      }

      if (this.mode == "add") {
        let code_add = document.getElementById("code");
        if (
          this.code == undefined ||
          this.code == null ||
          this.code == ""
        ) {
          code_add.setCustomValidity("Mã hsct không được để trống!");
        } else if (this.code.length > 50) {
          code_add.setCustomValidity("Mã hsct không được quá 50 ký tự!");
        } else if (await this.checkCodeExist()) {
          code_add.setCustomValidity(`Mã hsct ${this.code} đã tồn tại!`);
        } else {
          code_add.setCustomValidity("");
        }
      }

      let name_add = document.getElementById("name");
      if (
        this.name == undefined ||
        this.name == null ||
        this.name == ""
      ) {
        name_add.setCustomValidity("Tên hsct không được để trống!");
      } else if (this.name.length > 255) {
        name_add.setCustomValidity("Tên hsct không được quá 255 ký tự!");
      } else {
        name_add.setCustomValidity("");
      }

      let type_add = document.getElementById("type");
      if (
        this.type == undefined ||
        this.type == null ||
        this.type == "" ||
        this.type.length < 1
      ) {
        type_add.setCustomValidity("Loại hồ sơ không được để trống!");
      } else {
        type_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return;
      }

      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);

      var params = {
        name: this.name,
        type: this.type,
        company: this.selectedCompany,
        active: this.active,
        code: this.code,
      };
      this.$emit("save", params);
    },
  }
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}

.radio-option {
  margin-bottom: 0 !important;
}
.required:after {
  content: " *";
  color: red;
}
.p-checkbox {
  margin-right: 1rem !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
</style>
